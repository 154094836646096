import { useState } from "react";

import { constVoid, E, O, pipe } from "@scripts/fp-ts";
import type { ConfirmSitePasswordC, PrivateSite } from "@scripts/generated/models/privateSite";
import * as SR from "@scripts/generated/routers/sitesRouter";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { Anchor } from "@scripts/react/components/Anchor";
import { ButtonSubmit } from "@scripts/react/components/Button";
import { trueOrEmpty } from "@scripts/react/components/Empty";
import { Form } from "@scripts/react/components/form/Form";
import { PasswordInput } from "@scripts/react/components/form/PasswordInput";
import { stringC } from "@scripts/react/form/codecs";
import { emptyFormState, formDataLens, formLens } from "@scripts/react/form/form";
import { klass, klassConditional } from "@scripts/react/util/classnames";
import { openInSameTab } from "@scripts/routes/router";

import { MinimalContainer, MinimalFormContainer, MinimalHeader } from "@scripts-ssr/components/MinimalLayout";

import { usePagesSelector } from "../state/store";

const confirmPasswordL = formLens<ConfirmSitePasswordC>();
const confirmPasswordDL = formDataLens<ConfirmSitePasswordC>();


const makeFormState = (props: PrivateSite) => pipe(
  emptyFormState<ConfirmSitePasswordC>(),
  confirmPasswordDL.compose(confirmPasswordL("redirect")).set(props.redirect),
  confirmPasswordDL.compose(confirmPasswordL("id")).set(props.issuer.id),
);


export const PrivateSitePage = (props: PrivateSite) => {
  const user = usePagesSelector("user");
  const [formState, setFormState] = useState(makeFormState(props));
  const loginRoute = V2Router.baseAuthControllerLogin({
    issuerId: O.some(props.issuer.id),
    bankId: O.none,
    uhash: O.none,
    reason: O.none,
    redirect: O.some(props.redirect),
  });
  const isLoggedIn = O.isSome(user);

  return (
    <MinimalContainer>
      <MinimalHeader text="Private Site" />
      <p {...klassConditional("mb-0", O.none)(isLoggedIn)}>
        This site is under construction and not yet available to the public.
        {props.issuer.hasSitePassword && " If you have the password please enter it below."}
      </p>
      {!isLoggedIn && <p {...klass("mb-0")}>
        If you have a BondLink account that is authorized to view this content, you can <Anchor target="_self" route={{
          route: loginRoute,
          title: "log in",
        }}
        />{props.issuer.hasSitePassword && " instead"}.
      </p>}
      {trueOrEmpty(
        <MinimalFormContainer klasses={["mt-1"]}>
          <Form
            headers={O.none}
            onFailure={O.none}
            onSuccess={(r) => openInSameTab(r.data.redirect)}
            setState={setFormState}
            state={formState}
            url={SR.issuersitesIssuerControllerConfirmSitePassword()}
          >
            <PasswordInput
              autoComplete={"current-password"}
              codec={stringC}
              labelOrAriaLabel={E.left("Site Password")}
              lens={confirmPasswordL("publishPassword")}
              placeholder={O.none}
              setState={setFormState}
              state={formState}
            />
            <ButtonSubmit
              {...klass("w-100")}
              loading={formState.loading}
              loadingText={"Continuing"}
              onClick={constVoid}
              text={"Continue"}
            />
          </Form>
        </MinimalFormContainer>
      )(props.issuer.hasSitePassword)}
    </MinimalContainer>
  );
};
