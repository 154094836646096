import { RA } from "@scripts/fp-ts";
import type { TimeZoneU } from "@scripts/generated/domaintables/timeZones";
import { allTimeZone } from "@scripts/generated/domaintables/timeZones";
import type { Time, TimeWithZone } from "@scripts/generated/models/rfpBase";

import { timeUnknownStr } from "./date/joda";


export const formatTime = (time: Partial<Time>) => {
  if (typeof time.hour === "undefined" || typeof time.minute === "undefined") return timeUnknownStr;

  const h = time.hour === 0 ? 12 : time.hour > 12 ? time.hour % 12 : time.hour;
  const m = `0${time.minute}`.replace(/\d*(\d\d)/g, "$1");
  const amOrPmStr = time.hour >= 12 ? "PM" : "AM";

  return `${h}:${m} ${amOrPmStr}`;
};

export const formatTimeWithZone = (data: TimeWithZone<TimeZoneU>) => `${formatTime(data.time)} ${data.timezone.name}`;

export const noTimeSpecifiedConst = -1;
const noTimeSpecifiedStr = "No Time Specified";

const noTimeSpecified = {
  label: noTimeSpecifiedStr,
  value: {
    hour: noTimeSpecifiedConst,
    minute: noTimeSpecifiedConst,
  },
};

const noTimeZoneSpecified = {
  value: noTimeSpecifiedConst,
  label: noTimeSpecifiedStr,
};

type TimeType = {
  timeStr: string;
  id: number;
  value: {
      hour: number;
      minute: number;
  };
};

const allTimes: TimeType[] = Array(24 * 2).fill(0).map((_0, i) => {
  const hour = Math.floor((i % 48) / 2);
  const minute = 60 * (i / 2 % 1);

  const time = { hour, minute };

  return {
    timeStr: formatTime(time),
    id: i,
    value: time,
  };
});

const timeZoneSelectOptions = RA.map((tz: TimeZoneU) =>
  ({ value: tz.id, label: tz.name }))(allTimeZone);

const timeSelectOptions = RA.map((t: TimeType) =>
  ({ value: t.value, label: t.timeStr }))(allTimes);

export const clearableTimeZoneSelectOptions = [noTimeZoneSpecified, ...timeZoneSelectOptions];

export const clearableTimeSelectOptions = [noTimeSpecified, ...timeSelectOptions];
