import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const cusip9BondName = {
  _tag: `Cusip9BondName`,
  columnName: `bond_name`
} as const;

export type Cusip9BondNameTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Cusip9BondName`>
}>;
export const cusip9BondNameTaggedC: Cusip9BondNameTaggedC = t.type({
  _tag: t.literal(`Cusip9BondName`)
});
export type Cusip9BondNameTagged = t.TypeOf<Cusip9BondNameTaggedC>;
export type Cusip9BondName = Cusip9BondNameTagged & typeof cusip9BondName;
export type Cusip9BondNameC = t.Type<Cusip9BondName, Cusip9BondNameTagged>;
export const cusip9BondNameC: Cusip9BondNameC = pipe(cusip9BondNameTaggedC, c => new t.Type<Cusip9BondName, Cusip9BondNameTagged>(
  `Cusip9BondName`,
  (u: unknown): u is Cusip9BondName => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Cusip9BondName> => pipe(c.decode(u), E.map(x => ({ ...x, ...cusip9BondName }))),
  (x: Cusip9BondName): Cusip9BondNameTagged => ({ ...x, _tag: `Cusip9BondName`}),
)) satisfies t.Type<Cusip9BondName, unknown>;


export const cusip9Cusip = {
  _tag: `Cusip9Cusip`,
  columnName: `cusip`
} as const;

export type Cusip9CusipTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Cusip9Cusip`>
}>;
export const cusip9CusipTaggedC: Cusip9CusipTaggedC = t.type({
  _tag: t.literal(`Cusip9Cusip`)
});
export type Cusip9CusipTagged = t.TypeOf<Cusip9CusipTaggedC>;
export type Cusip9Cusip = Cusip9CusipTagged & typeof cusip9Cusip;
export type Cusip9CusipC = t.Type<Cusip9Cusip, Cusip9CusipTagged>;
export const cusip9CusipC: Cusip9CusipC = pipe(cusip9CusipTaggedC, c => new t.Type<Cusip9Cusip, Cusip9CusipTagged>(
  `Cusip9Cusip`,
  (u: unknown): u is Cusip9Cusip => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Cusip9Cusip> => pipe(c.decode(u), E.map(x => ({ ...x, ...cusip9Cusip }))),
  (x: Cusip9Cusip): Cusip9CusipTagged => ({ ...x, _tag: `Cusip9Cusip`}),
)) satisfies t.Type<Cusip9Cusip, unknown>;


export const cusip9IssueDate = {
  _tag: `Cusip9IssueDate`,
  columnName: `issue_date`
} as const;

export type Cusip9IssueDateTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Cusip9IssueDate`>
}>;
export const cusip9IssueDateTaggedC: Cusip9IssueDateTaggedC = t.type({
  _tag: t.literal(`Cusip9IssueDate`)
});
export type Cusip9IssueDateTagged = t.TypeOf<Cusip9IssueDateTaggedC>;
export type Cusip9IssueDate = Cusip9IssueDateTagged & typeof cusip9IssueDate;
export type Cusip9IssueDateC = t.Type<Cusip9IssueDate, Cusip9IssueDateTagged>;
export const cusip9IssueDateC: Cusip9IssueDateC = pipe(cusip9IssueDateTaggedC, c => new t.Type<Cusip9IssueDate, Cusip9IssueDateTagged>(
  `Cusip9IssueDate`,
  (u: unknown): u is Cusip9IssueDate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Cusip9IssueDate> => pipe(c.decode(u), E.map(x => ({ ...x, ...cusip9IssueDate }))),
  (x: Cusip9IssueDate): Cusip9IssueDateTagged => ({ ...x, _tag: `Cusip9IssueDate`}),
)) satisfies t.Type<Cusip9IssueDate, unknown>;


export const cusip9DatedDate = {
  _tag: `Cusip9DatedDate`,
  columnName: `dated_date`
} as const;

export type Cusip9DatedDateTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Cusip9DatedDate`>
}>;
export const cusip9DatedDateTaggedC: Cusip9DatedDateTaggedC = t.type({
  _tag: t.literal(`Cusip9DatedDate`)
});
export type Cusip9DatedDateTagged = t.TypeOf<Cusip9DatedDateTaggedC>;
export type Cusip9DatedDate = Cusip9DatedDateTagged & typeof cusip9DatedDate;
export type Cusip9DatedDateC = t.Type<Cusip9DatedDate, Cusip9DatedDateTagged>;
export const cusip9DatedDateC: Cusip9DatedDateC = pipe(cusip9DatedDateTaggedC, c => new t.Type<Cusip9DatedDate, Cusip9DatedDateTagged>(
  `Cusip9DatedDate`,
  (u: unknown): u is Cusip9DatedDate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Cusip9DatedDate> => pipe(c.decode(u), E.map(x => ({ ...x, ...cusip9DatedDate }))),
  (x: Cusip9DatedDate): Cusip9DatedDateTagged => ({ ...x, _tag: `Cusip9DatedDate`}),
)) satisfies t.Type<Cusip9DatedDate, unknown>;


export const cusip9MaturityDate = {
  _tag: `Cusip9MaturityDate`,
  columnName: `maturity_date`
} as const;

export type Cusip9MaturityDateTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Cusip9MaturityDate`>
}>;
export const cusip9MaturityDateTaggedC: Cusip9MaturityDateTaggedC = t.type({
  _tag: t.literal(`Cusip9MaturityDate`)
});
export type Cusip9MaturityDateTagged = t.TypeOf<Cusip9MaturityDateTaggedC>;
export type Cusip9MaturityDate = Cusip9MaturityDateTagged & typeof cusip9MaturityDate;
export type Cusip9MaturityDateC = t.Type<Cusip9MaturityDate, Cusip9MaturityDateTagged>;
export const cusip9MaturityDateC: Cusip9MaturityDateC = pipe(cusip9MaturityDateTaggedC, c => new t.Type<Cusip9MaturityDate, Cusip9MaturityDateTagged>(
  `Cusip9MaturityDate`,
  (u: unknown): u is Cusip9MaturityDate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Cusip9MaturityDate> => pipe(c.decode(u), E.map(x => ({ ...x, ...cusip9MaturityDate }))),
  (x: Cusip9MaturityDate): Cusip9MaturityDateTagged => ({ ...x, _tag: `Cusip9MaturityDate`}),
)) satisfies t.Type<Cusip9MaturityDate, unknown>;


export const cusip6Name = {
  _tag: `Cusip6Name`,
  columnName: `name`
} as const;

export type Cusip6NameTaggedC = t.TypeC<{
  _tag: t.LiteralC<`Cusip6Name`>
}>;
export const cusip6NameTaggedC: Cusip6NameTaggedC = t.type({
  _tag: t.literal(`Cusip6Name`)
});
export type Cusip6NameTagged = t.TypeOf<Cusip6NameTaggedC>;
export type Cusip6Name = Cusip6NameTagged & typeof cusip6Name;
export type Cusip6NameC = t.Type<Cusip6Name, Cusip6NameTagged>;
export const cusip6NameC: Cusip6NameC = pipe(cusip6NameTaggedC, c => new t.Type<Cusip6Name, Cusip6NameTagged>(
  `Cusip6Name`,
  (u: unknown): u is Cusip6Name => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Cusip6Name> => pipe(c.decode(u), E.map(x => ({ ...x, ...cusip6Name }))),
  (x: Cusip6Name): Cusip6NameTagged => ({ ...x, _tag: `Cusip6Name`}),
)) satisfies t.Type<Cusip6Name, unknown>;


export const cusip6Cusip6 = {
  _tag: `Cusip6Cusip6`,
  columnName: `cusip6`
} as const;

export type Cusip6Cusip6TaggedC = t.TypeC<{
  _tag: t.LiteralC<`Cusip6Cusip6`>
}>;
export const cusip6Cusip6TaggedC: Cusip6Cusip6TaggedC = t.type({
  _tag: t.literal(`Cusip6Cusip6`)
});
export type Cusip6Cusip6Tagged = t.TypeOf<Cusip6Cusip6TaggedC>;
export type Cusip6Cusip6 = Cusip6Cusip6Tagged & typeof cusip6Cusip6;
export type Cusip6Cusip6C = t.Type<Cusip6Cusip6, Cusip6Cusip6Tagged>;
export const cusip6Cusip6C: Cusip6Cusip6C = pipe(cusip6Cusip6TaggedC, c => new t.Type<Cusip6Cusip6, Cusip6Cusip6Tagged>(
  `Cusip6Cusip6`,
  (u: unknown): u is Cusip6Cusip6 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Cusip6Cusip6> => pipe(c.decode(u), E.map(x => ({ ...x, ...cusip6Cusip6 }))),
  (x: Cusip6Cusip6): Cusip6Cusip6Tagged => ({ ...x, _tag: `Cusip6Cusip6`}),
)) satisfies t.Type<Cusip6Cusip6, unknown>;


export const userId = {
  _tag: `UserId`,
  columnName: `id`
} as const;

export type UserIdTaggedC = t.TypeC<{
  _tag: t.LiteralC<`UserId`>
}>;
export const userIdTaggedC: UserIdTaggedC = t.type({
  _tag: t.literal(`UserId`)
});
export type UserIdTagged = t.TypeOf<UserIdTaggedC>;
export type UserId = UserIdTagged & typeof userId;
export type UserIdC = t.Type<UserId, UserIdTagged>;
export const userIdC: UserIdC = pipe(userIdTaggedC, c => new t.Type<UserId, UserIdTagged>(
  `UserId`,
  (u: unknown): u is UserId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UserId> => pipe(c.decode(u), E.map(x => ({ ...x, ...userId }))),
  (x: UserId): UserIdTagged => ({ ...x, _tag: `UserId`}),
)) satisfies t.Type<UserId, unknown>;


export const userName = {
  _tag: `UserName`,
  columnName: `name`
} as const;

export type UserNameTaggedC = t.TypeC<{
  _tag: t.LiteralC<`UserName`>
}>;
export const userNameTaggedC: UserNameTaggedC = t.type({
  _tag: t.literal(`UserName`)
});
export type UserNameTagged = t.TypeOf<UserNameTaggedC>;
export type UserName = UserNameTagged & typeof userName;
export type UserNameC = t.Type<UserName, UserNameTagged>;
export const userNameC: UserNameC = pipe(userNameTaggedC, c => new t.Type<UserName, UserNameTagged>(
  `UserName`,
  (u: unknown): u is UserName => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UserName> => pipe(c.decode(u), E.map(x => ({ ...x, ...userName }))),
  (x: UserName): UserNameTagged => ({ ...x, _tag: `UserName`}),
)) satisfies t.Type<UserName, unknown>;


export const userEmail = {
  _tag: `UserEmail`,
  columnName: `email`
} as const;

export type UserEmailTaggedC = t.TypeC<{
  _tag: t.LiteralC<`UserEmail`>
}>;
export const userEmailTaggedC: UserEmailTaggedC = t.type({
  _tag: t.literal(`UserEmail`)
});
export type UserEmailTagged = t.TypeOf<UserEmailTaggedC>;
export type UserEmail = UserEmailTagged & typeof userEmail;
export type UserEmailC = t.Type<UserEmail, UserEmailTagged>;
export const userEmailC: UserEmailC = pipe(userEmailTaggedC, c => new t.Type<UserEmail, UserEmailTagged>(
  `UserEmail`,
  (u: unknown): u is UserEmail => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UserEmail> => pipe(c.decode(u), E.map(x => ({ ...x, ...userEmail }))),
  (x: UserEmail): UserEmailTagged => ({ ...x, _tag: `UserEmail`}),
)) satisfies t.Type<UserEmail, unknown>;


export const userCompanyName = {
  _tag: `UserCompanyName`,
  columnName: `name`
} as const;

export type UserCompanyNameTaggedC = t.TypeC<{
  _tag: t.LiteralC<`UserCompanyName`>
}>;
export const userCompanyNameTaggedC: UserCompanyNameTaggedC = t.type({
  _tag: t.literal(`UserCompanyName`)
});
export type UserCompanyNameTagged = t.TypeOf<UserCompanyNameTaggedC>;
export type UserCompanyName = UserCompanyNameTagged & typeof userCompanyName;
export type UserCompanyNameC = t.Type<UserCompanyName, UserCompanyNameTagged>;
export const userCompanyNameC: UserCompanyNameC = pipe(userCompanyNameTaggedC, c => new t.Type<UserCompanyName, UserCompanyNameTagged>(
  `UserCompanyName`,
  (u: unknown): u is UserCompanyName => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UserCompanyName> => pipe(c.decode(u), E.map(x => ({ ...x, ...userCompanyName }))),
  (x: UserCompanyName): UserCompanyNameTagged => ({ ...x, _tag: `UserCompanyName`}),
)) satisfies t.Type<UserCompanyName, unknown>;


export const userCompanyCount = {
  _tag: `UserCompanyCount`,
  columnName: `count`
} as const;

export type UserCompanyCountTaggedC = t.TypeC<{
  _tag: t.LiteralC<`UserCompanyCount`>
}>;
export const userCompanyCountTaggedC: UserCompanyCountTaggedC = t.type({
  _tag: t.literal(`UserCompanyCount`)
});
export type UserCompanyCountTagged = t.TypeOf<UserCompanyCountTaggedC>;
export type UserCompanyCount = UserCompanyCountTagged & typeof userCompanyCount;
export type UserCompanyCountC = t.Type<UserCompanyCount, UserCompanyCountTagged>;
export const userCompanyCountC: UserCompanyCountC = pipe(userCompanyCountTaggedC, c => new t.Type<UserCompanyCount, UserCompanyCountTagged>(
  `UserCompanyCount`,
  (u: unknown): u is UserCompanyCount => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UserCompanyCount> => pipe(c.decode(u), E.map(x => ({ ...x, ...userCompanyCount }))),
  (x: UserCompanyCount): UserCompanyCountTagged => ({ ...x, _tag: `UserCompanyCount`}),
)) satisfies t.Type<UserCompanyCount, unknown>;


export const userCompanyCanned = {
  _tag: `UserCompanyCanned`,
  columnName: `canned`
} as const;

export type UserCompanyCannedTaggedC = t.TypeC<{
  _tag: t.LiteralC<`UserCompanyCanned`>
}>;
export const userCompanyCannedTaggedC: UserCompanyCannedTaggedC = t.type({
  _tag: t.literal(`UserCompanyCanned`)
});
export type UserCompanyCannedTagged = t.TypeOf<UserCompanyCannedTaggedC>;
export type UserCompanyCanned = UserCompanyCannedTagged & typeof userCompanyCanned;
export type UserCompanyCannedC = t.Type<UserCompanyCanned, UserCompanyCannedTagged>;
export const userCompanyCannedC: UserCompanyCannedC = pipe(userCompanyCannedTaggedC, c => new t.Type<UserCompanyCanned, UserCompanyCannedTagged>(
  `UserCompanyCanned`,
  (u: unknown): u is UserCompanyCanned => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UserCompanyCanned> => pipe(c.decode(u), E.map(x => ({ ...x, ...userCompanyCanned }))),
  (x: UserCompanyCanned): UserCompanyCannedTagged => ({ ...x, _tag: `UserCompanyCanned`}),
)) satisfies t.Type<UserCompanyCanned, unknown>;


export const emailSubject = {
  _tag: `EmailSubject`,
  columnName: `subject`
} as const;

export type EmailSubjectTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EmailSubject`>
}>;
export const emailSubjectTaggedC: EmailSubjectTaggedC = t.type({
  _tag: t.literal(`EmailSubject`)
});
export type EmailSubjectTagged = t.TypeOf<EmailSubjectTaggedC>;
export type EmailSubject = EmailSubjectTagged & typeof emailSubject;
export type EmailSubjectC = t.Type<EmailSubject, EmailSubjectTagged>;
export const emailSubjectC: EmailSubjectC = pipe(emailSubjectTaggedC, c => new t.Type<EmailSubject, EmailSubjectTagged>(
  `EmailSubject`,
  (u: unknown): u is EmailSubject => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EmailSubject> => pipe(c.decode(u), E.map(x => ({ ...x, ...emailSubject }))),
  (x: EmailSubject): EmailSubjectTagged => ({ ...x, _tag: `EmailSubject`}),
)) satisfies t.Type<EmailSubject, unknown>;


export const emailName = {
  _tag: `EmailName`,
  columnName: `name`
} as const;

export type EmailNameTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EmailName`>
}>;
export const emailNameTaggedC: EmailNameTaggedC = t.type({
  _tag: t.literal(`EmailName`)
});
export type EmailNameTagged = t.TypeOf<EmailNameTaggedC>;
export type EmailName = EmailNameTagged & typeof emailName;
export type EmailNameC = t.Type<EmailName, EmailNameTagged>;
export const emailNameC: EmailNameC = pipe(emailNameTaggedC, c => new t.Type<EmailName, EmailNameTagged>(
  `EmailName`,
  (u: unknown): u is EmailName => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EmailName> => pipe(c.decode(u), E.map(x => ({ ...x, ...emailName }))),
  (x: EmailName): EmailNameTagged => ({ ...x, _tag: `EmailName`}),
)) satisfies t.Type<EmailName, unknown>;


export const emailEmail = {
  _tag: `EmailEmail`,
  columnName: `email`
} as const;

export type EmailEmailTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EmailEmail`>
}>;
export const emailEmailTaggedC: EmailEmailTaggedC = t.type({
  _tag: t.literal(`EmailEmail`)
});
export type EmailEmailTagged = t.TypeOf<EmailEmailTaggedC>;
export type EmailEmail = EmailEmailTagged & typeof emailEmail;
export type EmailEmailC = t.Type<EmailEmail, EmailEmailTagged>;
export const emailEmailC: EmailEmailC = pipe(emailEmailTaggedC, c => new t.Type<EmailEmail, EmailEmailTagged>(
  `EmailEmail`,
  (u: unknown): u is EmailEmail => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EmailEmail> => pipe(c.decode(u), E.map(x => ({ ...x, ...emailEmail }))),
  (x: EmailEmail): EmailEmailTagged => ({ ...x, _tag: `EmailEmail`}),
)) satisfies t.Type<EmailEmail, unknown>;


export const emailSent = {
  _tag: `EmailSent`,
  columnName: `sent`
} as const;

export type EmailSentTaggedC = t.TypeC<{
  _tag: t.LiteralC<`EmailSent`>
}>;
export const emailSentTaggedC: EmailSentTaggedC = t.type({
  _tag: t.literal(`EmailSent`)
});
export type EmailSentTagged = t.TypeOf<EmailSentTaggedC>;
export type EmailSent = EmailSentTagged & typeof emailSent;
export type EmailSentC = t.Type<EmailSent, EmailSentTagged>;
export const emailSentC: EmailSentC = pipe(emailSentTaggedC, c => new t.Type<EmailSent, EmailSentTagged>(
  `EmailSent`,
  (u: unknown): u is EmailSent => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EmailSent> => pipe(c.decode(u), E.map(x => ({ ...x, ...emailSent }))),
  (x: EmailSent): EmailSentTagged => ({ ...x, _tag: `EmailSent`}),
)) satisfies t.Type<EmailSent, unknown>;


export const issuerName = {
  _tag: `IssuerName`,
  columnName: `issuer`
} as const;

export type IssuerNameTaggedC = t.TypeC<{
  _tag: t.LiteralC<`IssuerName`>
}>;
export const issuerNameTaggedC: IssuerNameTaggedC = t.type({
  _tag: t.literal(`IssuerName`)
});
export type IssuerNameTagged = t.TypeOf<IssuerNameTaggedC>;
export type IssuerName = IssuerNameTagged & typeof issuerName;
export type IssuerNameC = t.Type<IssuerName, IssuerNameTagged>;
export const issuerNameC: IssuerNameC = pipe(issuerNameTaggedC, c => new t.Type<IssuerName, IssuerNameTagged>(
  `IssuerName`,
  (u: unknown): u is IssuerName => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerName> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerName }))),
  (x: IssuerName): IssuerNameTagged => ({ ...x, _tag: `IssuerName`}),
)) satisfies t.Type<IssuerName, unknown>;


export const issuerBank = {
  _tag: `IssuerBank`,
  columnName: `bank`
} as const;

export type IssuerBankTaggedC = t.TypeC<{
  _tag: t.LiteralC<`IssuerBank`>
}>;
export const issuerBankTaggedC: IssuerBankTaggedC = t.type({
  _tag: t.literal(`IssuerBank`)
});
export type IssuerBankTagged = t.TypeOf<IssuerBankTaggedC>;
export type IssuerBank = IssuerBankTagged & typeof issuerBank;
export type IssuerBankC = t.Type<IssuerBank, IssuerBankTagged>;
export const issuerBankC: IssuerBankC = pipe(issuerBankTaggedC, c => new t.Type<IssuerBank, IssuerBankTagged>(
  `IssuerBank`,
  (u: unknown): u is IssuerBank => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerBank> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerBank }))),
  (x: IssuerBank): IssuerBankTagged => ({ ...x, _tag: `IssuerBank`}),
)) satisfies t.Type<IssuerBank, unknown>;


export const issuerStatus = {
  _tag: `IssuerStatus`,
  columnName: `status`
} as const;

export type IssuerStatusTaggedC = t.TypeC<{
  _tag: t.LiteralC<`IssuerStatus`>
}>;
export const issuerStatusTaggedC: IssuerStatusTaggedC = t.type({
  _tag: t.literal(`IssuerStatus`)
});
export type IssuerStatusTagged = t.TypeOf<IssuerStatusTaggedC>;
export type IssuerStatus = IssuerStatusTagged & typeof issuerStatus;
export type IssuerStatusC = t.Type<IssuerStatus, IssuerStatusTagged>;
export const issuerStatusC: IssuerStatusC = pipe(issuerStatusTaggedC, c => new t.Type<IssuerStatus, IssuerStatusTagged>(
  `IssuerStatus`,
  (u: unknown): u is IssuerStatus => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerStatus> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerStatus }))),
  (x: IssuerStatus): IssuerStatusTagged => ({ ...x, _tag: `IssuerStatus`}),
)) satisfies t.Type<IssuerStatus, unknown>;


export const issuerPublishDate = {
  _tag: `IssuerPublishDate`,
  columnName: `publish-date`
} as const;

export type IssuerPublishDateTaggedC = t.TypeC<{
  _tag: t.LiteralC<`IssuerPublishDate`>
}>;
export const issuerPublishDateTaggedC: IssuerPublishDateTaggedC = t.type({
  _tag: t.literal(`IssuerPublishDate`)
});
export type IssuerPublishDateTagged = t.TypeOf<IssuerPublishDateTaggedC>;
export type IssuerPublishDate = IssuerPublishDateTagged & typeof issuerPublishDate;
export type IssuerPublishDateC = t.Type<IssuerPublishDate, IssuerPublishDateTagged>;
export const issuerPublishDateC: IssuerPublishDateC = pipe(issuerPublishDateTaggedC, c => new t.Type<IssuerPublishDate, IssuerPublishDateTagged>(
  `IssuerPublishDate`,
  (u: unknown): u is IssuerPublishDate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerPublishDate> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerPublishDate }))),
  (x: IssuerPublishDate): IssuerPublishDateTagged => ({ ...x, _tag: `IssuerPublishDate`}),
)) satisfies t.Type<IssuerPublishDate, unknown>;


export const marketInsightTitle = {
  _tag: `MarketInsightTitle`,
  columnName: `title`
} as const;

export type MarketInsightTitleTaggedC = t.TypeC<{
  _tag: t.LiteralC<`MarketInsightTitle`>
}>;
export const marketInsightTitleTaggedC: MarketInsightTitleTaggedC = t.type({
  _tag: t.literal(`MarketInsightTitle`)
});
export type MarketInsightTitleTagged = t.TypeOf<MarketInsightTitleTaggedC>;
export type MarketInsightTitle = MarketInsightTitleTagged & typeof marketInsightTitle;
export type MarketInsightTitleC = t.Type<MarketInsightTitle, MarketInsightTitleTagged>;
export const marketInsightTitleC: MarketInsightTitleC = pipe(marketInsightTitleTaggedC, c => new t.Type<MarketInsightTitle, MarketInsightTitleTagged>(
  `MarketInsightTitle`,
  (u: unknown): u is MarketInsightTitle => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MarketInsightTitle> => pipe(c.decode(u), E.map(x => ({ ...x, ...marketInsightTitle }))),
  (x: MarketInsightTitle): MarketInsightTitleTagged => ({ ...x, _tag: `MarketInsightTitle`}),
)) satisfies t.Type<MarketInsightTitle, unknown>;


export const marketInsightDescription = {
  _tag: `MarketInsightDescription`,
  columnName: `description`
} as const;

export type MarketInsightDescriptionTaggedC = t.TypeC<{
  _tag: t.LiteralC<`MarketInsightDescription`>
}>;
export const marketInsightDescriptionTaggedC: MarketInsightDescriptionTaggedC = t.type({
  _tag: t.literal(`MarketInsightDescription`)
});
export type MarketInsightDescriptionTagged = t.TypeOf<MarketInsightDescriptionTaggedC>;
export type MarketInsightDescription = MarketInsightDescriptionTagged & typeof marketInsightDescription;
export type MarketInsightDescriptionC = t.Type<MarketInsightDescription, MarketInsightDescriptionTagged>;
export const marketInsightDescriptionC: MarketInsightDescriptionC = pipe(marketInsightDescriptionTaggedC, c => new t.Type<MarketInsightDescription, MarketInsightDescriptionTagged>(
  `MarketInsightDescription`,
  (u: unknown): u is MarketInsightDescription => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MarketInsightDescription> => pipe(c.decode(u), E.map(x => ({ ...x, ...marketInsightDescription }))),
  (x: MarketInsightDescription): MarketInsightDescriptionTagged => ({ ...x, _tag: `MarketInsightDescription`}),
)) satisfies t.Type<MarketInsightDescription, unknown>;


export const marketInsightDate = {
  _tag: `MarketInsightDate`,
  columnName: `date`
} as const;

export type MarketInsightDateTaggedC = t.TypeC<{
  _tag: t.LiteralC<`MarketInsightDate`>
}>;
export const marketInsightDateTaggedC: MarketInsightDateTaggedC = t.type({
  _tag: t.literal(`MarketInsightDate`)
});
export type MarketInsightDateTagged = t.TypeOf<MarketInsightDateTaggedC>;
export type MarketInsightDate = MarketInsightDateTagged & typeof marketInsightDate;
export type MarketInsightDateC = t.Type<MarketInsightDate, MarketInsightDateTagged>;
export const marketInsightDateC: MarketInsightDateC = pipe(marketInsightDateTaggedC, c => new t.Type<MarketInsightDate, MarketInsightDateTagged>(
  `MarketInsightDate`,
  (u: unknown): u is MarketInsightDate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MarketInsightDate> => pipe(c.decode(u), E.map(x => ({ ...x, ...marketInsightDate }))),
  (x: MarketInsightDate): MarketInsightDateTagged => ({ ...x, _tag: `MarketInsightDate`}),
)) satisfies t.Type<MarketInsightDate, unknown>;


export const marketInsightPublication = {
  _tag: `MarketInsightPublication`,
  columnName: `publication`
} as const;

export type MarketInsightPublicationTaggedC = t.TypeC<{
  _tag: t.LiteralC<`MarketInsightPublication`>
}>;
export const marketInsightPublicationTaggedC: MarketInsightPublicationTaggedC = t.type({
  _tag: t.literal(`MarketInsightPublication`)
});
export type MarketInsightPublicationTagged = t.TypeOf<MarketInsightPublicationTaggedC>;
export type MarketInsightPublication = MarketInsightPublicationTagged & typeof marketInsightPublication;
export type MarketInsightPublicationC = t.Type<MarketInsightPublication, MarketInsightPublicationTagged>;
export const marketInsightPublicationC: MarketInsightPublicationC = pipe(marketInsightPublicationTaggedC, c => new t.Type<MarketInsightPublication, MarketInsightPublicationTagged>(
  `MarketInsightPublication`,
  (u: unknown): u is MarketInsightPublication => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MarketInsightPublication> => pipe(c.decode(u), E.map(x => ({ ...x, ...marketInsightPublication }))),
  (x: MarketInsightPublication): MarketInsightPublicationTagged => ({ ...x, _tag: `MarketInsightPublication`}),
)) satisfies t.Type<MarketInsightPublication, unknown>;


export const allSortColumnC = [cusip9BondNameC, cusip9CusipC, cusip9IssueDateC, cusip9DatedDateC, cusip9MaturityDateC, cusip6NameC, cusip6Cusip6C, userIdC, userNameC, userEmailC, userCompanyNameC, userCompanyCountC, userCompanyCannedC, emailSubjectC, emailNameC, emailEmailC, emailSentC, issuerNameC, issuerBankC, issuerStatusC, issuerPublishDateC, marketInsightTitleC, marketInsightDescriptionC, marketInsightDateC, marketInsightPublicationC] as const;
export const allSortColumnNames = [`Cusip9BondName`, `Cusip9Cusip`, `Cusip9IssueDate`, `Cusip9DatedDate`, `Cusip9MaturityDate`, `Cusip6Name`, `Cusip6Cusip6`, `UserId`, `UserName`, `UserEmail`, `UserCompanyName`, `UserCompanyCount`, `UserCompanyCanned`, `EmailSubject`, `EmailName`, `EmailEmail`, `EmailSent`, `IssuerName`, `IssuerBank`, `IssuerStatus`, `IssuerPublishDate`, `MarketInsightTitle`, `MarketInsightDescription`, `MarketInsightDate`, `MarketInsightPublication`] as const;
export type SortColumnName = (typeof allSortColumnNames)[number];

export type SortColumnCU = t.UnionC<[Cusip9BondNameC, Cusip9CusipC, Cusip9IssueDateC, Cusip9DatedDateC, Cusip9MaturityDateC, Cusip6NameC, Cusip6Cusip6C, UserIdC, UserNameC, UserEmailC, UserCompanyNameC, UserCompanyCountC, UserCompanyCannedC, EmailSubjectC, EmailNameC, EmailEmailC, EmailSentC, IssuerNameC, IssuerBankC, IssuerStatusC, IssuerPublishDateC, MarketInsightTitleC, MarketInsightDescriptionC, MarketInsightDateC, MarketInsightPublicationC]>;
export type SortColumnU = Cusip9BondName | Cusip9Cusip | Cusip9IssueDate | Cusip9DatedDate | Cusip9MaturityDate | Cusip6Name | Cusip6Cusip6 | UserId | UserName | UserEmail | UserCompanyName | UserCompanyCount | UserCompanyCanned | EmailSubject | EmailName | EmailEmail | EmailSent | IssuerName | IssuerBank | IssuerStatus | IssuerPublishDate | MarketInsightTitle | MarketInsightDescription | MarketInsightDate | MarketInsightPublication;
export const SortColumnCU: SortColumnCU = t.union([cusip9BondNameC, cusip9CusipC, cusip9IssueDateC, cusip9DatedDateC, cusip9MaturityDateC, cusip6NameC, cusip6Cusip6C, userIdC, userNameC, userEmailC, userCompanyNameC, userCompanyCountC, userCompanyCannedC, emailSubjectC, emailNameC, emailEmailC, emailSentC, issuerNameC, issuerBankC, issuerStatusC, issuerPublishDateC, marketInsightTitleC, marketInsightDescriptionC, marketInsightDateC, marketInsightPublicationC]) satisfies t.Type<SortColumnU, unknown>;

export const sortColumnOrd: Ord.Ord<SortColumnU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allSortColumn = [cusip9BondName, cusip9Cusip, cusip9IssueDate, cusip9DatedDate, cusip9MaturityDate, cusip6Name, cusip6Cusip6, userId, userName, userEmail, userCompanyName, userCompanyCount, userCompanyCanned, emailSubject, emailName, emailEmail, emailSent, issuerName, issuerBank, issuerStatus, issuerPublishDate, marketInsightTitle, marketInsightDescription, marketInsightDate, marketInsightPublication] as const;
export type SortColumnMap<A> = { [K in SortColumnName]: A };


export const allCusip9SortColumnC = [cusip9BondNameC, cusip9CusipC, cusip9IssueDateC, cusip9DatedDateC, cusip9MaturityDateC] as const;
export const allCusip9SortColumnNames = [`Cusip9BondName`, `Cusip9Cusip`, `Cusip9IssueDate`, `Cusip9DatedDate`, `Cusip9MaturityDate`] as const;
export type Cusip9SortColumnName = (typeof allCusip9SortColumnNames)[number];

export type Cusip9SortColumnCU = t.UnionC<[Cusip9BondNameC, Cusip9CusipC, Cusip9IssueDateC, Cusip9DatedDateC, Cusip9MaturityDateC]>;
export type Cusip9SortColumnU = Cusip9BondName | Cusip9Cusip | Cusip9IssueDate | Cusip9DatedDate | Cusip9MaturityDate;
export const Cusip9SortColumnCU: Cusip9SortColumnCU = t.union([cusip9BondNameC, cusip9CusipC, cusip9IssueDateC, cusip9DatedDateC, cusip9MaturityDateC]) satisfies t.Type<Cusip9SortColumnU, unknown>;

export const cusip9SortColumnOrd: Ord.Ord<Cusip9SortColumnU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allCusip9SortColumn = [cusip9BondName, cusip9Cusip, cusip9IssueDate, cusip9DatedDate, cusip9MaturityDate] as const;
export type Cusip9SortColumnMap<A> = { [K in Cusip9SortColumnName]: A };


export const allCusip6SortColumnC = [cusip6NameC, cusip6Cusip6C] as const;
export const allCusip6SortColumnNames = [`Cusip6Name`, `Cusip6Cusip6`] as const;
export type Cusip6SortColumnName = (typeof allCusip6SortColumnNames)[number];

export type Cusip6SortColumnCU = t.UnionC<[Cusip6NameC, Cusip6Cusip6C]>;
export type Cusip6SortColumnU = Cusip6Name | Cusip6Cusip6;
export const Cusip6SortColumnCU: Cusip6SortColumnCU = t.union([cusip6NameC, cusip6Cusip6C]) satisfies t.Type<Cusip6SortColumnU, unknown>;

export const cusip6SortColumnOrd: Ord.Ord<Cusip6SortColumnU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allCusip6SortColumn = [cusip6Name, cusip6Cusip6] as const;
export type Cusip6SortColumnMap<A> = { [K in Cusip6SortColumnName]: A };


