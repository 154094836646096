import { useState } from "react";
import { useStableO } from "fp-ts-react-stable-hooks";

import { constVoid, O } from "@scripts/fp-ts";
import type { MfaInitiated, MfaInitiatedC } from "@scripts/generated/domaintables/loginStatus";
import type { EmptyObjectC } from "@scripts/generated/models/emptyObject";
import type { ResetPasswordMfaData } from "@scripts/generated/models/login";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { ButtonSubmit } from "@scripts/react/components/Button";
import { Form } from "@scripts/react/components/form/Form";
import type { FormSuccessAction } from "@scripts/react/form/form";
import { emptyFormState } from "@scripts/react/form/form";
import { klass } from "@scripts/react/util/classnames";

import { MinimalContainer, MinimalFormContainer, MinimalHeader } from "@scripts-ssr/components/MinimalLayout";

import { HavingTroubleLoggingIn, MFAVerification } from "../components/loginPage/MFAVerification";
import { ResetPasswordBody } from "./ResetPassword";

type OnRequestCodeSuccess = FormSuccessAction<EmptyObjectC, MfaInitiatedC>;

const RequestCode = (props: ResetPasswordMfaData & { onSuccess: OnRequestCodeSuccess }) => {
  const [requestCodeFormState, setRequestCodeFormState] = useState(emptyFormState<EmptyObjectC>());

  return (
    <>
      <MinimalHeader text="Request a verification code" />
      <p {...klass("mb-15")}>
        To continue, we first need to verify your identity. Press the button below and
        we’ll send a verification code to the phone number ending in <strong>{props.phoneLineNumber}</strong>.
      </p>
      <MinimalFormContainer klasses={["mb-15"]}>
        <Form
          headers={O.none}
          onFailure={O.none}
          onSuccess={props.onSuccess}
          setState={setRequestCodeFormState}
          state={requestCodeFormState}
          url={V2Router.baseAuthControllerConfirmPasswordRequestMfa({ e: props.reset.reset.email, h: props.reset.reset.hash })}
        >
          <ButtonSubmit
            {...klass("w-100", "mt-0")}
            loading={requestCodeFormState.loading}
            loadingText={"Requesting"}
            onClick={constVoid}
            text={"Request Code"}
          />
        </Form>
      </MinimalFormContainer>
      <HavingTroubleLoggingIn />
    </>
  );
};

export const MFAResetPassword = (props: ResetPasswordMfaData) => {
  const [verified, setVerified] = useState<boolean>(false);
  const [mfaInitiated, setMfaInitiated] = useStableO<MfaInitiated>(O.none);

  const onRequestCodeSuccess: OnRequestCodeSuccess = (r) => setMfaInitiated(O.some(r.data));
  const onVerificationSuccess = () => setVerified(true);

  return (
    <MinimalContainer>
      {O.fold(
        () => <RequestCode {...props} onSuccess={onRequestCodeSuccess} />,
        (m: MfaInitiated) => verified
          ? <ResetPasswordBody
            expired={props.reset.expired}
            reset={props.reset.reset}
            verificationId={O.some(m.verificationId)}
          />
          : <MFAVerification
            mfaInitiated={m}
            onSuccess={onVerificationSuccess}
            showRememberCheckbox={false}
          />
      )(mfaInitiated)}
    </MinimalContainer>
  );
};
