import { useState } from "react";
import { constVoid, pipe } from "fp-ts/lib/function";
import type { Dispatch } from "redux";

import { E, O } from "@scripts/fp-ts";
import { info } from "@scripts/generated/domaintables/alertTypes";
import type { MfaInitiated } from "@scripts/generated/domaintables/loginStatus";
import type { ResendMfaSmsC } from "@scripts/generated/models/mfa";
import type { MfaSettingsResponse, VerifyMfaPostC } from "@scripts/generated/models/userSettings";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { Alert } from "@scripts/react/components/Alert";
import { ButtonPrimary, ButtonSubmit } from "@scripts/react/components/Button";
import { trueOrEmpty } from "@scripts/react/components/Empty";
import { ActionButtons } from "@scripts/react/components/form/ActionButtons";
import { Form } from "@scripts/react/components/form/Form";
import { Input } from "@scripts/react/components/form/TextInput";
import type { ModalActions } from "@scripts/react/components/modal/Modal";
import { Modal } from "@scripts/react/components/modal/Modal";
import { ModalWithDiscard } from "@scripts/react/components/modal/ModalWithDiscard";
import { stringC } from "@scripts/react/form/codecs";
import { emptyFormState, formDataLens, formLens } from "@scripts/react/form/form";
import type { BaseActions } from "@scripts/react/state/store";
import { userSet } from "@scripts/react/state/user";
import { klass, type KlassList, klassPropO } from "@scripts/react/util/classnames";
import { type OpenModalO } from "@scripts/react/util/useModal";

import checkmark from "@svgs/checkmark.svg";
import lock from "@svgs/lock.svg";

const resendFormL = formLens<ResendMfaSmsC>();
const resendFormDL = formDataLens<ResendMfaSmsC>();

export const ResendCodeForm = (props: {
  verification: MfaInitiated;
  onSuccess: () => void;
  klasses?: KlassList;
}) => {
  const [resendCodeFormState, setResendCodeFormState] = useState(pipe(
    emptyFormState<ResendMfaSmsC>(),
    resendFormDL.compose(resendFormL("userId")).set(props.verification.userId),
    resendFormDL.compose(resendFormL("verificationId")).set(props.verification.verificationId)
  ));

  return (
    <Form
      url={V2Router.baseAuthControllerResendMfaSms()}
      state={resendCodeFormState}
      setState={setResendCodeFormState}
      onSuccess={props.onSuccess}
      onFailure={O.none}
      headers={O.none}
    >
      <div {...klassPropO([])(props.klasses)}>
        Code expired or don’t see it?
        <ButtonSubmit
          {...klass("ml-05")}
          onClick={constVoid}
          variant="link"
          text={"Send another code"}
          loading={resendCodeFormState.loading}
          loadingText={"Sending"}
        />
      </div>
    </Form>
  );
};

const verfiyFormL = formLens<VerifyMfaPostC>();
const verfiyFormDL = formDataLens<VerifyMfaPostC>();

type VerificationBaseProps = ModalActions & {
  settingsResp: MfaSettingsResponse;
};

export const VerificationFormModal = (props: VerificationBaseProps & {
  dispatch: Dispatch<BaseActions>;
  onSuccess: () => void;
  openModal: OpenModalO<MfaSettingsResponse>;
}) => {
  const verifyState = pipe(
    emptyFormState<VerifyMfaPostC>(),
    verfiyFormDL.compose(verfiyFormL("verificationId")).set(props.settingsResp.verification.verificationId)
  );

  const [codeResent, setCodeResent] = useState<boolean>(false);

  return (
    <ModalWithDiscard
      dismissAction={props.dismissAction}
      id="verify-2fa-phone-number"
      title={"Verify Cell Phone Number"}
      icon={O.some(lock)}
      type={"primary"}
      open={props.modalOpen}
      size="modal-sm"
      initialState={verifyState}
    >
      {({ state, setState }, handleDismiss) =>
        <div>
          {trueOrEmpty(
            <Alert icon={false} pill={false} type={info}>A new code has been sent.</Alert>
          )(codeResent)}
          <p>
            We’ve sent a verification code via text message to the cell phone number ending
            in <strong>{props.settingsResp.verification.phoneLineNumber}</strong>. This code
            expires in 5 minutes. Please enter the code below to verify your phone number.
          </p>
          <Input
            state={state}
            setState={setState}
            type="text"
            placeholder={O.none}
            labelOrAriaLabel={E.left("Verification Code")}
            lens={verfiyFormL("totp")}
            codec={stringC}
          />
          <ResendCodeForm
            verification={props.settingsResp.verification}
            onSuccess={() => setCodeResent(true)}
          />
          <Form
            url={V2Router.investorPortalUserControllerPostVerifyMfa()}
            state={state}
            setState={setState}
            onSuccess={(r) => {
              props.openModal({ verification: props.settingsResp.verification, user: r.data });
              props.dispatch(userSet(r.data));
              props.onSuccess();
              props.dismissAction();
            }}
            onFailure={O.none}
            headers={O.none}
          >
            <ActionButtons
              noDivider
              deleteButton={O.none}
              dismissAction={handleDismiss}
              loading={state.loading}
              onSubmit={constVoid}
              submitText="submit"
            />
          </Form>
        </div>
      }
    </ModalWithDiscard>
  );
};

export const VerificationConfirmationModal = (props: VerificationBaseProps) => {
  return (
    <Modal
      dismissAction={props.dismissAction}
      id="verify-2fa-phone-number"
      title={"Verify Cell Phone Number"}
      icon={O.some(checkmark)}
      type={"primary"}
      open={props.modalOpen}
      size="modal-sm"
      body={
        <div>
          <p>
            We’ve verified your cell phone number ending
            in <strong>{props.settingsResp.verification.phoneLineNumber}</strong>.
            We’ll use this number to send login verification codes.
          </p>
          <ButtonPrimary onClick={props.dismissAction}>
            OK
          </ButtonPrimary>
        </div>}
    />
  );
};
