export * from "fp-ts/lib/ReadonlyRecord";

import type { Option } from "fp-ts/lib/Option";
import type { ReadonlyRecord as ROR } from "fp-ts/lib/ReadonlyRecord";
import * as RR from "fp-ts/lib/ReadonlyRecord";

export const dataFirstLookup = <A>(r: ROR<string, A>) => (k: string): Option<A> => RR.lookup(k, r);

/**
 * `RR.unit` is similar to `RR.singleton` in that it creates a record containing
 * a single key/value pair, but unlike `singleton`, `unit` returns a record whose
 * key is the string literal that is passed in (rather than widening to `string`).
 *
 * @example
 * ```typescript
 *  import * as RR from "@scripts/fp-ts/ReadonlyRecord"
 *  const singleton = RR.singleton("foo", 1)
 *  //=> ExpectType: { [key: string]: number; }
 *  const unit = RR.unit("foo")(1)
 *  //=> ExpectType: { foo: number; }
 * ```
 */
export const unit = <K extends string>(key: K) =>
  <V>(value: V): { [P in K]: V } =>
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    ({ [key]: value } as { [P in K]: V });

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export const values = <A extends ROR<string, unknown>, K extends keyof A>(r: A): ReadonlyArray<A[K]> =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  Object.values(r) as ReadonlyArray<A[K]>;
